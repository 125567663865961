import React, { useState, useEffect } from 'react';
import styles from './login.module.css';
import PageLayout from '../../../components/layout/Page';
import { Box, Button, Image, Input, Typography } from '../../../components/ui';
import Container from '../../../components/ui/container';

import { fingerPrintOutline, lockClosedOutline } from 'ionicons/icons';
import { setIsLoggedIn, setUsername } from '../../../data/user/user.actions';
import { connect } from '../../../data/connect';
import { RouteComponentProps } from 'react-router';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import * as userActions from '../../../reducers/user/actions';

const Login: React.FC = ({ history, match }: any) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.user.loading);
  const { user } = useSelector((state: any) => ({ user: state.user }));

  const [code, setCode] = useState('');
  const [pass, setPass] = useState('');
  const [codeWarning, setCodeWarning] = useState('');
  const [passWarning, setPassWarning] = useState('');
  useEffect(() => {
    if (user.isLogged) {
      history.push(`/checkin`, { direction: 'none' });
    }
  }, [user.isLogged]);

  const login = (code: string, pass: string) => {
    if (!code.trim()) {
      setCodeWarning('Código do operador é obrigatório.');
    } else {
      setCodeWarning('');
    }
    if (!pass) {
      setPassWarning('Senha é obrigatória.');
    } else {
      setPassWarning('');
    }
    if (code && pass) {
      dispatch(userActions.login(code, pass));
    }
  };

  return (
    <PageLayout showNavbar={false} showNavbutton={false} pageId='login-page'>
      <Container className={styles.container}>
        <Box className={styles.logoBox}>
          <Image
            width='96px'
            height='96px'
            src={'/assets/icon/logo.svg'}
            alt='logo'
            className={styles.logo}
          />
          <Box className={styles.logoBoxTexts}>
            <Typography className={styles.logoTitle} tag='h1'>
              CHECK-
              <Typography className={styles.logoTitleIn} tag='p'>
                IN
              </Typography>
            </Typography>
            <Typography className={styles.logoParagraph} tag='h1'>
              Você no controle do seu evento com a Ticket and Go.
            </Typography>
          </Box>
        </Box>
        {/* box dos inputs de codigo do operador e senha */}
        <Box className={styles.inputsBox}>
          <Input
            label='Código do operador *'
            placeholder='Insira o código '
            onChange={(e) => setCode(e.target.value)}
            warning={codeWarning}
          />
          <Input
            label='Senha*'
            placeholder='Digite sua senha'
            onChange={(e) => setPass(e.target.value)}
            type='password'
            warning={passWarning}
          />
        </Box>
        {/* botão de entrar */}
        <Button
          disabled={loading}
          label='Entrar'
          onClick={() => login(code, pass)}
          loading={loading}
        />

        {/* footer */}
        <Box className={styles.footerBox}>
          <Image
            className={styles.footerLogo}
            src='/assets/icon/logo_ticket.png'
            alt='logo-ticket'
          />
          <Typography className={styles.footerParagraph}>
            Ajudamos você a vivenciar experiencias únicas.
          </Typography>
          <Typography className={styles.footerAppVersion}>
            Versão 2.0.3
          </Typography>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default Login;
